/**
 * File hamburger.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
import Menu from './Menu';
import $ from 'jquery';

$( function( ) {
	new Menu( 'site-navigation' );
} );
