/**
 * File hamburger.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
import $ from 'jquery';

$( function( ) {
	const hamburger = $( '.hamburger' );
	const navigation = $( '#site-navigation' );
	const body = $( 'body' );
	var currentWidth = $( window ).width();

	if ( 960 <= $( window ).width() ) {
		hamburger.attr( 'aria-expanded', 'false' );
		navigation.attr( 'aria-expanded', 'true' );
		body.removeClass( 'state-menu-open' );
	} else {
		hamburger.attr( 'aria-expanded', 'false' );
		navigation.attr( 'aria-expanded', 'false' );
	}

	$( window ).on(
		'resize',
		function() {
			if ( currentWidth == $( window ).width() ) {
				// ウインドウ横幅が変わっていないため処理をキャンセル。
				return;
			}

			// ウインドウ横幅が変わったのでリサイズと見なす。
			// 横幅を更新
			currentWidth = $( window ).width();
			if ( 960 <= $( window ).width() ) {
				hamburger.attr( 'aria-expanded', 'false' );
				navigation.attr( 'aria-expanded', 'true' );
				body.removeClass( 'state-menu-open' );
			} else {
				hamburger.attr( 'aria-expanded', 'false' );
				navigation.attr( 'aria-expanded', 'false' );
			}
		}
	);
} );
