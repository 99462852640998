import $ from 'jquery';

$( function( ) {
	$( '.faqs__title' ).on( 'click', function(){
		$( this ).toggleClass( 'is-open' );

		if ( $( this ).hasClass( 'is-open' ) ) {
			$( this ).next().addClass( 'is-open' );
		} else {
			$( this ).next().removeClass( 'is-open' );
		}
	});
} );
